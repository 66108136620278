import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React from 'react';
import Logo from '../../public/images/logo360sc-horizontal.png';

const CustomTypography = styled(Typography)({
    color: 'white',
    fontSize: 10,
    fontWeight: 400,
    fontStyle: 'italic',
});

const CustomDiv = styled('div')({
    width: 200,
});

const RootDiv = styled('div')({
    backgroundColor: '#22263D',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 50,
    padding: '7px 7px 0px 7px',
});

const Footer = () => {
    const tCommon = useTranslations('common');

    return (
        <RootDiv>
            <CustomTypography>{tCommon('texts.poweredBy')}</CustomTypography>

            <CustomDiv>
                <Image src={Logo} alt='' layout={'intrinsic'} />
            </CustomDiv>
        </RootDiv>
    );
};

export default Footer;
